//React component for local form

import { useState, useCallback } from "react";
import Customer from "./Customer";
import './App.scss';
import Checkboxes from "./Checkboxes";
import Material from "./Material.js";
import Activities from "./Activities";
import Todoes from "./Todoes";
import ChooseDate from "./date";
import Signature from "./signature";
import submitToServer from "./submit";
import SaveButton from "./saveFormButton";
import { useNavigate } from 'react-router-dom';

const LocalForm = ({ username, selectedEntry }) => {
    const [customer, setCustomer] = useState('');
    const [checkboxes, setCheckboxes] = useState('');
    const [material, setMaterial] = useState('');
    const [activities, setActivities] = useState('');
    const [todoes, setTodoes] = useState('');
    const [time, setTime] = useState('');
    const [signature, setSignature] = useState('');
    const navigate = useNavigate();
  
    const technician = username;

    const handleCustomerChange = useCallback((value) => {
      setCustomer(value);
    }, []);
  
    const handleCheckboxChange = useCallback((value) => {
      setCheckboxes(value);
    }, []);
    
    const handleMaterialChange = useCallback((value) => {
      setMaterial(value);
    }, []);
  
    const handleActivitiesChange = useCallback((value) => {
      setActivities(value);
    }, []);
  
    const handleTodoesChange = useCallback((value) => {
      setTodoes(value);
    }, []);
  
    const handleDateChange = useCallback((value) => {
      setTime(value);
    }, []);
  
    const handleSignatureChange = useCallback((value) => {
      setSignature(value);
    }, []);

    const handleSaveClick = () => {
      let data = ['local', technician, customer, checkboxes, material, activities, todoes, time, signature]
      return data;
    }
  
    const handleSubmit = async (e) => { //send data to server
      e.preventDefault();
      let data = ['local', technician, customer, checkboxes, material, activities, todoes, time, signature]
      if (data[2] === "" || data[6][1] === "" || data[6][2] === "") {
        window.alert('Bitte alle mit * markierten Felder ausfüllen');
        return;
      } else {
        submitToServer(data);
        navigate(`/`)
      }
    }

    return(
        <form onSubmit={handleSubmit}>
          <h1>Vor-Ort-Wartung</h1>
          <label htmlFor = "technician">Techniker</label><br />
          <input type = "text" name = "technician" id = "technician" value = {technician} readOnly />
          {!selectedEntry ? (
            <>
            <Customer onClientChange={handleCustomerChange} form={'local'} />
            <Checkboxes onCheckboxChange={handleCheckboxChange} form={'local'} />
            <Material onMaterialChange={handleMaterialChange} />
            <Activities onActivitiesChange={handleActivitiesChange} />
            <Todoes onTodoesChange={handleTodoesChange} />
            <ChooseDate onDateChange={handleDateChange} />
            <Signature savedSignature={handleSignatureChange} />
            </>
          ) : (
            <>
            <Customer onClientChange={handleCustomerChange} restoredEntry={selectedEntry.customerName} form={'local'} />
            <Checkboxes onCheckboxChange={handleCheckboxChange} restoredEntry={selectedEntry.checkboxes} form={'local'} />
            <Material onMaterialChange={handleMaterialChange} restoredEntry={selectedEntry.material} />
            <Activities onActivitiesChange={handleActivitiesChange} restoredEntry={selectedEntry.activities} />
            <Todoes onTodoesChange={handleTodoesChange} restoredEntry={selectedEntry.todoes} />
            <ChooseDate onDateChange={handleDateChange} restoredEntry={selectedEntry.date} />
            <Signature savedSignature={handleSignatureChange} restoredEntry={selectedEntry.signature} />
            </>
          )
          }
          <SaveButton saveClick={handleSaveClick} />
          <button type="submit" className="customButton" id="submit">Abschicken</button>
        </form>
    )
}

export default LocalForm