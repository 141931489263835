import ReactDOM from "react-dom/client";
import './App.scss';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Technikerschein from "./technikerschein";
import RemoteForm from "./remote";
import LocalForm from "./local";
import Farbkalibrierung from "./farbkalibrierung";
import SavedForms from "./savedForms";
import Archive from "./archive";
import AdminPage from "./admin";
import Login from "./login";
import Menu from "./menu";
import { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import ChangePw from "./changePw";

// function for getting the value of a specific cookie
export function getCookieName(cookieName) {
    let cookies = document.cookie.split(";"); //if more than 1 cookie, seperate them
    const token = localStorage.getItem("token");

    for (let i = 0; i < cookies.length; i++) { //go through cookies and look for correct one
        let cookie = cookies[i];

        while (cookie.charAt(0) === " ") { //delete empty first char
            cookie = cookie.substring(1);
        }

        if (cookie.indexOf(cookieName) === 0 && (token !== "" || token)) { //if found, return the value
            return cookie.substring(cookieName.length + 1, cookie.length).split('.');
        }
    }

    return null; //if nothing is found, return null
}

// look for GET-Parameters
function getParameter(){
    let params = window.location.search;
    let paramValue = params.substring(1);
    return paramValue
};
const parameter = getParameter();

// component for landing page
const App = () => {
    const [isServerRunning, setIsServerRunning] = useState(false);

    //check if server is running
    useEffect(() => {
        checkServerStatus();
    }, []);

    const checkServerStatus = async () => {
        try {
            const response = await fetch('http://checklist.epserver.at:4343/checkServer');
            if (response.status === 200) {
                setIsServerRunning(true);
            } 
        } catch (err) {
            console.log('Server offline: ', err);
        }
    };

    let loggedIn = getCookieName('user'); //get the name of the user from the cookie with the name 'user' or null if not logged in.

    //render menu/form or login-page, if user is not logged in
return (
<Router>
<Header loggedIn={ loggedIn }/>
{!isServerRunning ? (
<p>Server ist offline</p>
) : 
loggedIn ? (
      <Routes>
        <Route path="/" element={<Menu userRole={loggedIn[1]} />} />
        <Route path="/technikerschein" element={<Technikerschein username={loggedIn[0]} />} />
        <Route path="/remote-wartung" element={<RemoteForm username={loggedIn[0]} />} />
        <Route path="/vor-ort-wartung" element={<LocalForm username={loggedIn[0]} />} />
        <Route path="/gespeicherte formulare" element={<SavedForms username={loggedIn[0]} />} />
        <Route path="/archiv" element={<Archive username={loggedIn[0]} />} />
        <Route path="/farbkalibrierung" element={<Farbkalibrierung username={loggedIn[0]} />} />
        <Route path="/admin-bereich" element={<AdminPage username={loggedIn[0]} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
) : (
<>
{parameter === 'changePw' ? <ChangePw /> : <Login />}
</>
)}
<Footer />
</Router>
)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);