import { useState } from 'react';
import MaterialDropdowns from "./materialdropdowns.js";

const Material = ({ onMaterialChange, restoredEntry }) => {
  const [materialText, setMaterialText] = useState('');
  const [isRestored, setIsRestored] = useState(false);

  const handleMaterialChange = (e) => {
    const value = e.target.value;
    setMaterialText(value);
    onMaterialChange(value);
  }

  function replaceCounter(value){
    let number = +value[0]
    let newNumber = number+1
    let newString = newNumber.toString();
    for (let i = 1; i < value.length; i++) {
      newString += value[i];
    }
    return newString;
  }

  const setPart = (value) => {
    let regex = new RegExp(`\\d+x\\s+${value}`);
    let match = materialText.match(regex);
    if (match) {
      let newValue = replaceCounter(match[0]);
      let newText = materialText.replace(match[0], newValue);
      setMaterialText(newText);
      onMaterialChange(newText);
    } else {
      setMaterialText(prevMaterialText => prevMaterialText + "1x " + value + "\n");
      onMaterialChange(prevMaterialText => prevMaterialText + "1x " +  value + "\n")
    }
  }

  if (restoredEntry && !isRestored) {
    setIsRestored(true);
    setMaterialText(restoredEntry);
    onMaterialChange(restoredEntry);
  }

  return(
    <>
    <MaterialDropdowns setPart={setPart} />
    <div id='material'>
      <label htmlFor='materialText'>Material:</label>
      <textarea id='materialText' rows="10" placeholder="Hier einfügen" value={materialText} onChange={handleMaterialChange}></textarea>
    </div>
    </>
  )
}

export default Material;