import { useEffect, useState } from "react";

function MaterialDropdowns({ setPart }) {
  const [materials, setMaterials] = useState([])
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [parts, setParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState('');

  useEffect(() => {
    const fetchMaterial = async () => {
      try {
        const response = await fetch('http://checklist.epserver.at:4343/api/getMaterial');
        const data = await response.json();
        setMaterials(data)
      } catch (err) {
        console.log('Fehler beim Abruf des Materials: ', err);
      }
    };

    fetchMaterial();
  }, []);

  const handleManufacturerChange = (e) => {
    const selectedManufacturer = e.target.value;
    const filteredModels = materials.filter(material => material.manufacturer === selectedManufacturer).map(material => material.model);
    const uniqueModels = [];
    filteredModels.forEach(model => {
      if (!uniqueModels.includes(model)) {
        uniqueModels.push(model);
      }
    });
    setModels(uniqueModels);
    setParts([]);
  };

  const handleModelChange = (e) => {
    const selectedModel = e.target.value;
    const selectedManufacturer = manufacturers.find(manufacturer => materials.some(material => material.manufacturer === manufacturer && material.model === selectedModel));
    const filteredParts = materials.filter(material => material.manufacturer === selectedManufacturer && material.model === selectedModel).map(material => material.part);
    setParts(filteredParts)
  };

  const handlePartsChange = (e) => {
    const chosenPart = e.target.value;
    setSelectedPart(chosenPart);
  }

  const handleButton = (e) => {
    e.preventDefault();
    setPart(selectedPart);
  }

  useEffect(() => {
    const uniqueManufacturers = [...new Set(materials.map(material => material.manufacturer))];
    setManufacturers(uniqueManufacturers);
  }, [materials]);

  return (
    <>
    <div id="sparepartsLabel">gängiges Material:</div>
    <div id="spareparts">
      <label htmlFor="manufacturer">Hersteller:</label>
      <select onChange={handleManufacturerChange} id="manufacturer">
        <option value="">Hersteller auswählen</option>
        {manufacturers.map(manufacturer => (
          <option key={manufacturer} value={manufacturer}>{manufacturer}</option>
        ))}
      </select>
      {
      models.length > 0 && (
        <>
      <label htmlFor="model">Modell:</label>
      <select onChange={handleModelChange} id="model">
        <option value="">Modell auswählen</option>
        {models.map(model => (
          <option key={model} value={model}>{model}</option>
        ))}
      </select>
        </>
      )
      }
      {
      parts.length > 0 && (
        <>
        <label htmlFor="part">Ersatzteil:</label>
        <select onChange={handlePartsChange} id="part">
          <option value="">Ersatzteil auswählen</option>
          {parts.map(part => (
            <option key={part} value={part}>{part}</option>
          ))}
        </select>
        </>
      )
    }
    {
    selectedPart !== "" && <button id="addMaterialButton" type="button" onClick={handleButton}>hinzufügen</button>
    }
    </div>
    </>
  )
}

export default MaterialDropdowns;