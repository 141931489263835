import { useState, useCallback } from "react";import Customer from "./Customer.js";

import Checkboxes from "./Checkboxes.js";
import Activities from "./Activities.js";
import Todoes from "./Todoes.js";
import ChooseDate from "./date.js";
import submitToServer from "./submit.js";
import SaveButton from "./saveFormButton.js";
import { useNavigate } from 'react-router-dom';
import Signature from './signature.js';

const Farbkalibrierung = ({ username, selectedEntry }) => {
  const [remote, setRemote] = useState(true);
  const [customer, setCustomer] = useState('');
  const [checkboxes, setCheckboxes] = useState('');
  const [activities, setActivities] = useState('');
  const [todoes, setTodoes] = useState('');
  const [time, setTime] = useState('');
  const [secondTime, setSecondTime] = useState('');
  const [signature, setSignature] = useState('');
  const navigate = useNavigate();

  const technician = username;

  const handlefarbCheckboxChange = useCallback((value) => {
    setRemote(value.target.checked)
    if (remote) {
      setSignature('');
      setSecondTime('');
    }
  }, []);

  const handleCustomerChange = useCallback((value) => {
    setCustomer(value);
  }, []);

  const handleCheckboxChange = useCallback((value) => {
    setCheckboxes(value);
  }, []);

  const handleActivitiesChange = useCallback((value) => {
    setActivities(value);
  }, []);

  const handleTodoesChange = useCallback((value) => {
    setTodoes(value);
  }, []);

  const handleDateChange = useCallback((value) => {
    setTime(value);
  }, []);
  
  const handleSecondDateChange = useCallback((value) => {
    setSecondTime(value);
  }, []);

  const handleSignatureChange = useCallback((value) => {
    setSignature(value);
  }, []);

  const handleSaveClick = () => {
    let data = ['farbkalibrierung', technician, customer, checkboxes, '', activities, todoes, time, signature, secondTime]
    return data;
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = ['farbkalibrierung', technician, customer, checkboxes, activities, todoes, time, signature, secondTime]
    if (data[2] === "" || data[6][1] === "" || data[6][2] === "") {
      window.alert('Bitte alle mit * markierten Felder ausfüllen');
      return;
    } else {
      submitToServer(data);
      navigate(`/`)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <h1>Farbkalibrierung</h1>
      <input type="checkbox" id="farbCheckbox" onChange={handlefarbCheckboxChange} checked={remote}></input>
      <label htmlFor="farbCheckbox">Farbkalibrierung vor Ort</label><br /><br />
      <label htmlFor="technician">Techniker</label><br />
      <input type="text" name="technician" id="technician" value={technician} readOnly />
      {!selectedEntry ? (
        <>
        <br />
        <Customer onClientChange={handleCustomerChange} form={'farbkalibrierung'} />
        <Checkboxes form={'Farbkalibrierung'} onCheckboxChange={handleCheckboxChange} />
        <Activities onActivitiesChange={handleActivitiesChange}/>
        <Todoes onTodoesChange={handleTodoesChange}/>
        <ChooseDate onDateChange={handleDateChange}/>
        {remote && (
          <>
          <ChooseDate onDateChange={handleSecondDateChange} optional={true} />
          <Signature savedSignature={handleSignatureChange} />
          </>
        )}
        </>
      ) : (
        <>
        <Customer onClientChange={handleCustomerChange} restoredEntry={selectedEntry.customerName} form={'farbkalibrierung'} />
        <Checkboxes form={'Farbkalibrierung'} onCheckboxChange={handleCheckboxChange} restoredEntry={selectedEntry.checkboxes} />
        <Activities onActivitiesChange={handleActivitiesChange} restoredEntry={selectedEntry.activities} />
        <Todoes onTodoesChange={handleTodoesChange} restoredEntry={selectedEntry.todoes} />
        <ChooseDate onDateChange={handleDateChange} restoredEntry={selectedEntry.date} />
        {remote && (
          <>
          <ChooseDate onDateChange={handleSecondDateChange} optional={true} />
          <Signature savedSignature={handleSignatureChange} restoredEntry={selectedEntry.signature} />
          </>
        )}
        </>
      )}
      <SaveButton saveClick={handleSaveClick}/>
      <button type="submit" className="customButton" id="submit">Abschicken</button>
    </ form>
  )
}

export default Farbkalibrierung;