//React component for remote form

import { useState, useCallback } from "react";
import './App.scss';
import Customer from "./Customer.js";
import Checkboxes from "./Checkboxes.js";
import Activities from "./Activities.js";
import Todoes from "./Todoes.js";
import ChooseDate from "./date.js";
import submitToServer from "./submit.js";
import SaveButton from "./saveFormButton.js";
import { useNavigate } from 'react-router-dom';

const RemoteForm = ({ username, selectedEntry }) => {
  //save changed values in own variables
  const [customer, setCustomer] = useState('');
  const [checkboxes, setCheckboxes] = useState('');
  const [activities, setActivities] = useState('');
  const [todoes, setTodoes] = useState('');
  const [time, setTime] = useState('');
  const navigate = useNavigate();

  const technician = username;

  const handleCustomerChange = useCallback((value) => {
    setCustomer(value);
  }, []);

  const handleCheckboxChange = useCallback((value) => {
    setCheckboxes(value);
  }, []);

  const handleActivitiesChange = useCallback((value) => {
    setActivities(value);
  }, []);

  const handleTodoesChange = useCallback((value) => {
    setTodoes(value);
  }, []);

  const handleDateChange = useCallback((value) => {
    setTime(value);
  }, []);

  const handleSaveClick = () => {
    let data = ['remote', technician, customer, checkboxes, '', activities, todoes, time, null]
    return data;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = ['remote', technician, customer, checkboxes, activities, todoes, time]
    if (data[2] === "" || data[6][1] === "" || data[6][2] === "") {
      window.alert('Bitte alle mit * markierten Felder ausfüllen');
      return;
    } else {
      submitToServer(data);
      navigate(`/`)
    }
  }

  //render complete form
  return (
    <form onSubmit={handleSubmit}>
      <h1>Remote-Wartung</h1>
      <label htmlFor = "technician">Techniker</label><br />
      <input type = "text" name = "technician" id = "technician" value = {technician} readOnly />
      {!selectedEntry ? (
          <>
          <Customer onClientChange={handleCustomerChange} form={'remote'} />
          <Checkboxes onCheckboxChange={handleCheckboxChange} />
          <Activities onActivitiesChange={handleActivitiesChange} />
          <Todoes onTodoesChange={handleTodoesChange} />
          <ChooseDate onDateChange={handleDateChange} />
          </>
        ) : (
          <>
          <Customer onClientChange={handleCustomerChange} restoredEntry={selectedEntry.customerName} form={'remote'} />
          <Checkboxes onCheckboxChange={handleCheckboxChange} restoredEntry={selectedEntry.checkboxes} />
          <Activities onActivitiesChange={handleActivitiesChange} restoredEntry={selectedEntry.activities} />
          <Todoes onTodoesChange={handleTodoesChange} restoredEntry={selectedEntry.todoes} />
          <ChooseDate onDateChange={handleDateChange} restoredEntry={selectedEntry.date} />
          </>
      )}
      <SaveButton saveClick={handleSaveClick} />
      <button type="submit" className="customButton" id="submit">Abschicken</button>
    </form>
  );
}

export default RemoteForm