//React component for the todo textarea

import { useState } from 'react';

const Todoes = ({ onTodoesChange, restoredEntry }) => {
    const [todoesText, setTodoesText] = useState('');
    const [isRestored, setIsRestored] = useState(false);

    const handleTodoesChange = (e) => { //change value on entering text and send changed value to upper component
        const value = e.target.value;
        setTodoesText(value);
        onTodoesChange(value);
    }

    if (restoredEntry && !isRestored) {
        setIsRestored(true);
        setTodoesText(restoredEntry);
        onTodoesChange(restoredEntry);
    }


    return(
        <div id="todoes">
        <label htmlFor="todoesText">Noch zu erledigen:</label>
        <textarea id="todoesText" rows="10" placeholder="Hier einfügen" value={todoesText} onChange={handleTodoesChange}></textarea>
        </div>
    )
}

export default Todoes;