import { useState, useEffect } from "react";

const Userlist = () => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await fetch('http://checklist.epserver.at:4343/api/getUsers');
      const data = await response.json();
      setUsers(data);
    } catch (err) {
      console.log('Fehler beim Abruf der User: ', err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleButtonClick = async (e) => {
    let target = e.currentTarget.parentElement.childNodes[1].innerText;
    await fetch('http://checklist.epserver.at:4343/deleteUser', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: target })
  })
  .then(response => response.json())
  .then(data => {
    if (data.error) {
      window.alert('Fehler: ' + data.error);
      return;
    } else {
      window.alert(data.message);
      fetchUsers();
    };
  })
  .catch(error => {
      console.log('Fehler: ' + error);
  });
  };

  return (
    <div id="userOverview">
      <h2>Übersicht über die registrierten User</h2>
      {users.map((user) => (
        <div className="useritem" key={user.ID}>
          <span>{user.user}</span>
          <span>{user.email}</span>
          <span>{user.role}</span>
          {  
          user.user !== "admin" ? (
            <>
            <button id="deleteUser" onClick={handleButtonClick}>User löschen</button>
            </>
          ) : (
            <span></span>
          )
          }
        </div>
      ))}
    </div>
  );
};

export default Userlist;