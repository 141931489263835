//React component for login page

import { useState } from "react";
import setCookie from "./setCookie";

const Login = () => {
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => { 
        e.preventDefault(); //prevent GET
        
        //check if login is correct, ask server to ask db
        const response = await fetch('http://checklist.epserver.at:4343/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: userEmail, pw: password })
        })

        if (response.ok) {
            console.log('Login erfolgreich');
            let answer = await response.json();
            const role = answer.role;
            const username = answer.username
            localStorage.setItem('token', answer.token)
            setCookie('user', username+'.'+role);

            await fetch('http://checklist.epserver.at:4343/api/updateCustomers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify()
                })
                .then(res => res.json())
                .then(data => {
                    console.log(data.message);
                })
                .catch(err => {
                    console.log('Fehler beim Aktualisieren der Kunden: ', err);
                });

                window.location.reload(); //reload page to load on Menu    
        } else {
            console.log('Login fehlgeschlagen');
            return(window.alert('Falsche E-Mail-Adresse oder falsches Passwort')); //alert user of incorrect data
        }
    }

    //render login form
    return(
        <div className="loginForm">
            <h1>Willkommen</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">E-Mail-Adresse:</label>
                <br />
                <input type="text" id="email" placeholder="E-Mail-Adresse" onChange={(e) => setUserEmail(e.target.value)} />
                <br />
                <br />
                <label htmlFor="password">Passwort:</label>
                <br />
                <input type="password" id="password" placeholder="Passwort" onChange={(e) => setPassword(e.target.value)} />
                <br />
                <button type="submit" className="customButton" id="pwSubmit">Anmelden</button>
            </form>
        </div>
    )

}

export default Login