//react component for foter

function Footer() {
    return (
      <footer>
        <a href="tel: +43 5223 54959-0">+43 5223 54959-0</a>
        <a href="mailto: technik.intern@e-p.at">technik.intern@e-p.at</a>
      </footer>
    );
  }
  
  export default Footer;