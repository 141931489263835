//component to create a new password

import { useState } from 'react';

const ChangePw = () => {
    const [userEmail, setUserEmail] = useState('');
    const [oldPw, setOldPw] = useState('');
    const [newPw, setNewPw] = useState('');
    const [confirmPw, setConfirmPw] = useState('');

    const handleSubmit = async(e) => {
        e.preventDefault();

        if (newPw !== confirmPw) {
            window.alert('Passwörter stimmen nicht überein!')
            window.location.reload();
            return;
        }
        try {
            const response = await fetch('http://checklist.epserver.at:4343/changePw', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: userEmail, oldPw: oldPw, newPw: newPw })
            })

            if (response.ok) {
                window.alert('Passwort wurde erfolgreich geändert. Bitte melden Sie sich mit dem neuen Passwort an.')
                window.location.assign('http://checklist.epserver.at');
            } else {
                window.alert('Passwort ändern fehlgeschlagen. Bitte prüfen Sie die Eingaben.')
                window.location.reload();
            }
        } catch (err) {
            if (err) console.log('Fehler bei Anfrage an Server: ', err);
        };
    }

    return(
        <div className="loginForm">
            <h1>Passwort ändern</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="user">E-Mail-Adresse:</label>
                <br />
                <input type="text" id="checkUser" placeholder="E-Mail-Adresse" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} required />
                <br />
                <br />
                <label htmlFor="oldPw">altes Passwort:</label>
                <br />
                <input type="password" id="oldPw" placeholder="altes Passwort" value={oldPw} onChange={(e) => setOldPw(e.target.value)} />
                <br />
                <br />
                <label htmlFor="newPw">neues Passwort:</label>
                <br />
                <input type="password" id="newPw" placeholder="neues Passwort" value={newPw} onChange={(e) => setNewPw(e.target.value)} required />
                <br />
                <br />
                <label htmlFor="confirmPw">neues Passwort wiederholen:</label>
                <br />
                <input type="password" id="confirmPw" placeholder="neues Passwort wiederholen" value={confirmPw} onChange={(e) => setConfirmPw(e.target.value)} required />
                <br />
                <button type="submit" className="customButton" id="pwChange">Passwort ändern</button>
            </form>
        </div>
    )
}

export default ChangePw