//React component for Customerlist

import { useState, useMemo, useCallback } from 'react';

let AllCustomers
let customers; 
//fetch all customers from database
await fetch('http://checklist.epserver.at:4343/api/customers')
  .then(res => res.json())
  .then(data => {
    AllCustomers = data;
  })
  .catch(err => {
    console.log('Fehler beim Abrufen der Daten: ', err);
});
//make a copy for usage and custom lists
customers = AllCustomers;

//searchbar
function SearchCustomer({ foundCustomer }) {
  const [searchResults, setSearchResults] = useState([]);

  function handleSearchBar(e) {
    let filter = e.target.value.toLowerCase();
    const result = customers.filter(customer => customer.customer.toLowerCase().includes(filter));
    setSearchResults(result);
    if (filter === "") setSearchResults([]);
  }

  function handleSearchClick(e) {
    foundCustomer(e.target.innerText);
    console.log(e.target.innerText)
    setSearchResults('')
  }

  return(
    <div id='searchCustomer'>
      <input type='search' placeholder='Kunden suchen...' onChange={handleSearchBar}></input>
      {searchResults.length > 0 && (
        <ul id='resultList'>
          {searchResults.map((customer, index) => (
            <li key={index} onClick={handleSearchClick}>{customer.customer}</li>
          ))}
        </ul>
      )  
      }
    </div>
  )
}

//list for customers in western austria
function AustriaWestList({ onCustomerChange }) {
  function handleChange(e) {
    onCustomerChange(e.target.value); //give chosen value to upper component
  }

  const AustriaList = customers.filter(fun => fun.country === 'Ö'); //filter list for austrian customers
  const listItems = AustriaList.map(fun => {
    const zip0 = String(fun.zip)[0]
    if (zip0 === '5' || zip0 === '6') { //filter through zip-code if western or eastern austria
      return <option key={fun.customer} value={fun.customer}>{fun.customer} ({fun.city})</option> //return <option> for dropdown-menu
    } else {return null}
  })
  return (
    <div className='cust'>
    <br />
    <select onChange={handleChange}>
      <option>Kunde auswählen</option>
      {listItems}
    </select>
    </div>
  )
}

//list for customers in eastern austria
function AustriaEastList({ onCustomerChange }) {
  function handleChange(e) {
    onCustomerChange(e.target.value); //give chosen value to upper component
  }

  const AustriaList = customers.filter(fun => fun.country === 'Ö'); //filter list for austrian customers
  const listItems = AustriaList.map(fun => {
    const zip0 = String(fun.zip)[0]
    if (zip0 !== '5' && zip0 !== '6') { //filter through zip-code if western or eastern austria
      return <option key={fun.customer} value={fun.customer}>{fun.customer} ({fun.city})</option> //return <option> for dropdown-menu
    } else {return null}
  })
  return (
    <div className='cust'>
      <br />
    <select onChange={handleChange}>
      <option>Kunde auswählen</option>
      {listItems}
    </select>
    </div>
  )
}

//choose region and show list of this region
function AustriaList({ onCustomerChange }) {
  const [aut, setAut] = useState('')

  function handleChange(e) {
    setAut(e.target.value) //give chosen value to upper component
  }

  return (
    <>
    <div id="region">
      <br />
      <select onChange={handleChange}>
        <option>Gebiet auswählen</option>
        <option value='east'>Ost</option>
        <option value='west'>West</option>
      </select>
    </div>
      {aut === 'east' && <AustriaEastList onCustomerChange={onCustomerChange} />}
      {aut === 'west' && <AustriaWestList onCustomerChange={onCustomerChange} />}
    </>
  )
}

//list of german customers
function GermanyList({ onCustomerChange }) {
  function handleChange(e) {
    onCustomerChange(e.target.value); //give chosen value to upper component
  }

  const listItems = customers.map(fun => { //filter list for german customers
    if (fun.country === 'D') {
      return <option key={fun.customer} value={fun.customer}>{fun.customer} ({fun.city})</option> //return <option> for dropdown-menu
    } else {return null}
  });
  return (
    <div className='cust'>
    <br />
    <select onChange={handleChange}>
      <option>Kunde auswählen</option>
      {listItems}
    </select>
    </div>
  )
}

//list of italian customers
function ItalyList({ onCustomerChange }) {
  function handleChange(e) {
    onCustomerChange(e.target.value); //give chosen value to upper component
  }

  const listItems = customers.map(fun => { //filter list for italian customers
    if (fun.country === 'I') {
      return <option key={fun.customer} value={fun.customer}>{fun.customer} ({fun.city})</option> //return <option> for dropdown-menu
    } else {return null}
  });
  return (
    <div className='cust'>
    <br />
    <select onChange={handleChange}>
      <option>Kunde auswählen</option>
      {listItems}
      </select>
    </div>
  )
}

//start of customer component
function CountryDropDown({ onCountryChange }) {
  function handleChange(e) {
    onCountryChange(e.target.value); //give chosen value to upper component
  }

  //select country
  return (
    <select onChange={handleChange} >
      <option>Land auswählen</option>
      <option value="austria">Österreich</option>
      <option value="germany">Deutschland</option>
      <option value="italy">Italien</option>
    </select>
    )
}

//component to finally show the customer in input areas
const ShowCustomer = (props) => {

  const MemoizedValue = useMemo(() => { //memoize it so it doesn't get rendered indefinitely
    const funName = props.chosenCustomer; //get the name of the chosen customer from props
    for (let obj of customers) { //look for customer data in list of customers
      if (obj.customer === funName) {
        let completeCountry = ""
        switch (obj.country) { //correct the country
          case 'Ö':
            completeCountry = "Österreich";
            break;
          case 'A':
            completeCountry = "Österreich";
            break;
          case 'D':
            completeCountry = "Deutschland";
            break;
          case 'I':
            completeCountry = "Italien";
            break;
          default:
            break;
        }

        function handleButtonPress() {
          const toPrint = document.getElementsByClassName('customerData');
          const newWindow = window.open();
          const adress = toPrint[1].value.split(",")[0];
          let zip = toPrint[1].value.split(",")[1].trim();
          let htmlContent = `
            <style>
              p {
                font-family: Arial, Helvetica, sans-serif;
                margin: 0 0 3px 0;
                font-size: 12px;
              }
            </style>
            <p>${toPrint[0].value}</p>
            <p>${adress}</p>
            `
          if (toPrint[2].value !== "Österreich") {
            htmlContent += `
              <p>${zip.toUpperCase()}</p>
              <p>${toPrint[2].value.toUpperCase()}</p>`
          } else {
            htmlContent += `
            <p>${zip}</p>`
          }
          
          newWindow.document.write(htmlContent);
          newWindow.print();
          newWindow.close();
        }

        return (
          <div id="allCustomerData">
            <label id='labelForCustomer'>Kundendaten:</label>
            <input type='text' id='CName' className='customerData' readOnly value={obj.customer}></input>
            <input type='text' id='CAdress' className='customerData' readOnly value={`${obj.street}, ${obj.zip} ${obj.city}`}></input>
            <input type='text' id='CCountry' className='customerData' readOnly value={completeCountry}></input>
            <input type='email' id='CEmail' className='customerData' readOnly value={obj.email} onClick={() => {window.location.href = `mailto:${obj.email}`}} style={{color: "#0075b9"}}></input>
            <input type='text' id='CTelephone' className='customerData' readOnly value={obj.telephone} onClick={() => {window.open(`tel:${obj.telephone}`)}} style={{color: "#0075b9"}}></input>
            <input type='text' id='CContact' className='customerData' readOnly value={obj.contact}></input>
            <input type='text' id='CSeller' className='customerData' readOnly value={obj.seller} hidden></input>
            <button type='button' id='adressLabel' className='customerData' onClick={handleButtonPress}>Adressetikett drucken</button>
          </div>
        );
      }
    }
    return null;
  }, [props.chosenCustomer]);

  //render data
  return (
    <>
      {MemoizedValue}
    </>
  );
};

//complete component for rendering customer options and customer data
const Customer = ({ onClientChange, restoredEntry, form }) => {
  const [chosenCountry, setChosenCountry] = useState('');
  const [chosenCustomer, setChosenCustomer] = useState('');
  const [isRestored, setIsRestored] = useState(false);

  let filter = false;
  if (form === 'farbkalibrierung') filter = 'F';
  if (filter) {
    const filteredCustomers = customers.filter(customer => customer.contract === filter);
    customers = filteredCustomers;
  } else {
    customers = AllCustomers;
  }

  const checkRestored = useCallback(() => {
    setIsRestored(true);
    if (restoredEntry !== "kein Kunde ausgewählt") handleCustomerChange(restoredEntry)
  }, [])
  
  if (restoredEntry && !isRestored) {
    checkRestored();
  }
  
  function handleCountryChange(value) { //update if country is changed
    setChosenCountry(value);
  }

  async function handleCustomerChange(value) { //update if customer is changed
    await new Promise(res => {
      res(setChosenCustomer(value))
    })
    const wrapper = Array.from(document.getElementById('allCustomerData').childNodes);
    wrapper.shift();
    const CustomerData = {}
    for (let data of wrapper) {
      let obj = data.id;
      CustomerData[obj] = data.value;
    }
    onClientChange(CustomerData); //save all data in object and give it to upper component
  }
  
  //render component
  return (
    <div id="customers">
      <SearchCustomer foundCustomer={handleCustomerChange}/>
      <div id="country">
        <label htmlFor = "country"><span style={{ color:"red" }}>*</span>Kunde:</label><br />
        <CountryDropDown onCountryChange={handleCountryChange} />
      </div>
      {chosenCountry==='austria' && <AustriaList onCustomerChange={handleCustomerChange} />}
      {chosenCountry==='germany' && <GermanyList onCustomerChange={handleCustomerChange} />}
      {chosenCountry==='italy' && <ItalyList onCustomerChange={handleCustomerChange} />}
      <ShowCustomer chosenCustomer={chosenCustomer} />
    </div>
  )
}

export default Customer