import { useEffect, useState } from "react";
import Technikerschein from "./technikerschein";
import RemoteForm from "./remote";
import LocalForm from "./local";
import Farbkalibrierung from "./farbkalibrierung";

const SavedForms = ({ username }) => {
  const [allEntries, setAllEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [searchedEntries, setSearchedEntries] = useState([]);

  function restoreForm(e) {
    e.stopPropagation()

    const chosenForm = e.currentTarget.id;
    
    for (let obj of allEntries) {
      if (obj.ID.toString() === chosenForm) {
        setSelectedEntry(obj);
      };
    };    
  };
  
  const handleDelete = (e) => {
    e.stopPropagation()
    if (window.confirm(`Möchten Sie diesen Eintrag löschen?\n\n${e.currentTarget.previousElementSibling.innerText}`)){
      try {
        fetch('http://checklist.epserver.at:4343/deleteForm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: e.currentTarget.id })
        })
        .then(response => response.json())
        .then(message => {
          window.alert(message.message);
          fetchTableData();
        })
        .catch(err => {console.log('Fehler bei der Abfrage: ', err)});
      } catch (error) {console.log('Fehler bei der Verbindung zum Server: ', error)};
    }
  }

  const fetchTableData = () => {
    try {
      fetch('http://checklist.epserver.at:4343/loadForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username: username, table: 'savedForms' })
      })
        .then(response => response.json())
        .then(allEntries => {
          setAllEntries(allEntries);
        })
        .catch(err => {
          console.log('Fehler bei der Abfrage: ', err);
        });
    } catch (error) {
      console.log('Fehler bei der Verbindung zum Server: ', error);
    }
  }

  useEffect(() => {
    fetchTableData();
  }, [username]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredEntries = allEntries.filter(entry => entry.customerName.toLowerCase().startsWith(searchTerm) || entry.form.toLowerCase().startsWith(searchTerm));
    setSearchedEntries(filteredEntries);
  }
  
  if (!selectedEntry) {
    return (
      <div id="savedForms">
        <h1>Übersicht über die gespeicherten Formulare</h1>
        <input type="search" placeholder="Suche..." id="searchInput" onChange={handleSearch}></input>
        <div id="formList">
          <div id="savedFormsRow1">
              <span>Name des Kunden</span>
              <span>Formular</span>
              <span>gespeichert am</span>
              <span></span>
          </div>
          {searchedEntries.length === 0 ? (
            allEntries.toReversed().map(item => {
              const fullDate = new Date(item.saveDate);
              const date = `${String(fullDate.getDate()).padStart(2, 0)}. ${String(fullDate.getMonth()+1).padStart(2, 0)}. ${fullDate.getFullYear()}, ${String(fullDate.getHours()).padStart(2, '0')}:${String(fullDate.getMinutes()).padStart(2, '0')} Uhr`

              if (!item.customerName) {item.customerName = 'kein Kunde ausgewählt'};

              return (
                <div key={item.ID}>
                  <span id={item.ID} className='formsItem' onClick={(e) => restoreForm(e, item.customerName)}>
                    <span>{item.customerName}</span>
                    <span>{item.form.charAt(0).toUpperCase()+item.form.slice(1)}</span>
                    <span>{date}</span>
                    <button id={item.ID} type="button" className="deleteEntry" onClick={handleDelete}>löschen</button>
                  </span>
                </div>
              );
            })
          ) : (
            searchedEntries.toReversed().map(item => {
              const fullDate = new Date(item.saveDate);
              const date = `${String(fullDate.getDate()).padStart(2, 0)}. ${String(fullDate.getMonth()+1).padStart(2, 0)}. ${fullDate.getFullYear()}, ${String(fullDate.getHours()).padStart(2, '0')}:${String(fullDate.getMinutes()).padStart(2, '0')} Uhr`

              if (!item.customerName) {item.customerName = 'kein Kunde ausgewählt'};

              return (
                <div key={item.ID}>
                  <span id={item.ID} className='formsItem' onClick={(e) => restoreForm(e, item.customerName)}>
                    <span>{item.customerName}</span>
                    <span>{item.form.charAt(0).toUpperCase()+item.form.slice(1)}</span>
                    <span>{date}</span>
                    <button id={item.ID} type="button" className="deleteEntry" onClick={handleDelete}>löschen</button>
                  </span>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  } else {
    return (
      <>
        {selectedEntry.form === 'technikerschein' && <Technikerschein username={selectedEntry.technician} selectedEntry={selectedEntry} />}
        {selectedEntry.form === 'remote' && <RemoteForm username={selectedEntry.technician} selectedEntry={selectedEntry} />}
        {selectedEntry.form === 'local' && <LocalForm username={selectedEntry.technician} selectedEntry={selectedEntry} />}
        {selectedEntry.form === 'farbkalibrierung' && <Farbkalibrierung username={selectedEntry.technician} selectedEntry={selectedEntry} />}
      </>
    )
  }
};
  
export default SavedForms;

