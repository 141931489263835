//React Component for activities textarea

import { useState } from 'react';

const Activities = ({ onActivitiesChange, restoredEntry }) => {
    const [activitiesText, setActivitiesText] = useState(''); 
    const [isRestored, setIsRestored] = useState(false);

    const handleActivitiesChange = (e) => { //set the value of the textarea on change
        const value = e.target.value;
        setActivitiesText(value); //set the value
        onActivitiesChange(value) //send data to upper component
    }

    if (restoredEntry && !isRestored) {
        setIsRestored(true);
        setActivitiesText(restoredEntry);
        onActivitiesChange(restoredEntry);
    }

    return(
        <div id = "material">
        <label htmlFor="matText">Weitere Tätigkeiten/Anmerkungen:</label>
        <textarea id="matText" rows="10" placeholder="Hier einfügen" value={activitiesText} onChange={handleActivitiesChange}></textarea>
        </div>
    )
}

export default Activities;