//component for the admin-page

import { useState } from 'react';
import Userlist from './users';

//validating the email
function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

const AdminPage = () => {
    const [newUserName, setNewUserName] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [resetPw, setResetPw] = useState('');
    const [role, setRole] = useState('Administrator');

    async function handleSubmitNewUser (e) {
        e.preventDefault();

        if (!validateEmail(newUserEmail)) {
            window.alert('Bitte geben Sie eine gültige E-Mail-Adresse ein!')
            return;
        }

        await fetch('http://checklist.epserver.at:4343/newUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ newUser: newUserName, newEmail: newUserEmail, role: role })
        })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            window.alert('Fehler: ' + data.error);
          } else {
            window.alert(data.message);
          }
        })
        .catch(error => {
          console.log('Fehler: ' + error.message);
        });
    }

    async function handleSubmitNewPw(e) {
        e.preventDefault();

        await fetch('http://checklist.epserver.at:4343/resetPw', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user: resetPw })
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                window.alert('Fehler: ' + data.error);
            } else {
                window.alert(data.message);
            }
        })
        .catch(error => {
            console.log('Fehler: ' + error.message);
        })
    }

    return(
        <div id="adminpage">
            <h1>Adminseite</h1>
            <div id="adminForm">
                <form id="newUserForm" onSubmit={handleSubmitNewUser}>
                    <h2>Neuen User anlegen</h2>
                    <label htmlFor="newUsername">Name:</label>
                    <input
                        type="text"
                        id="newUsername"
                        className='newUserInput'
                        placeholder='Vorname Nachname'
                        value={newUserName}
                        onChange={(e) => setNewUserName(e.target.value)}
                        required />
                    <label htmlFor="newUserEmail">E-Mail-Adresse:</label>
                    <input
                        type="text"
                        id="newUserEmail"
                        className='newUserInput'
                        placeholder='E-Mail'
                        value={newUserEmail}
                        onChange={(e) => setNewUserEmail(e.target.value)}
                        required />
                    <label htmlFor="selectRole">Rolle auswählen:</label>
                    <select
                        id="setRole" 
                        className='newUserInput'
                        value={role}
                        onChange={(e) => setRole(e.target.value)}>
                        <option>Administrator</option>
                        <option>User</option>
                    </select>
                    <button type="submit" className="customButton">anlegen</button>
                </form>
                <form id="resetPWForm" onSubmit={handleSubmitNewPw}>
                    <h2>Passwort eines Users zurücksetzen</h2>
                    <label htmlFor="newPw">E-Mail-Adresse:</label>
                    <input type="text" id="newPw" value={resetPw} placeholder="E-Mail" onChange={(e) => setResetPw(e.target.value)} />
                    <button type="submit" className="customButton">zurücksetzen</button>
                </form>
            </div>
            <Userlist />
        </div>
    )
}

export default AdminPage