//React module for date
import React, { useState, useEffect } from "react";

const ChooseDate = ({ onDateChange, restoredEntry, formtype, optional }) => {
  let today = new Date().toISOString().split('T')[0]
  const [option, setOption] = useState(false);
  const [day, setDay] = useState(today);
  const [FromTime, setFromTime] = useState('');
  const [ToTime, setToTime] = useState('');
  const [TravelTime, setTravelTime] = useState('');
  const [isRestored, setIsRestored] = useState(false);

  function handleDateChange(e) {
    setDay(e.target.value);
  };

  function handleFromTimeChange(e) {
    setFromTime(e.target.value);
  };

  function handleToTimeChange(e) {
    setToTime(e.target.value);
  };

  function handleTravelTimeChange(e) {
    setTravelTime(e.target.value);
  };
  
  
  useEffect(() => {
    setOption(optional)
    onDateChange([day, FromTime, ToTime, TravelTime]);
  }, [day, FromTime, ToTime, TravelTime, onDateChange]);
  
  if (restoredEntry && !isRestored) {
    setIsRestored(true);
    const dateArr = restoredEntry.split(" ")
    setDay(dateArr[0]);
    setFromTime(dateArr[1]);
    setToTime(dateArr[2]);
    setTravelTime(dateArr[3])
    today = day;
  };

  return (
    <div id="date">
      {!option ? (<span className="dateItem">
        <label htmlFor="workDate"><span style={{ color:"red" }}>*</span>Datum:</label>
        <br />
        <input type="date" onChange={handleDateChange} id="workDate" value={day || today} />
      </span>) : (
        <div className="dateItem">Zeit für weitere Tätigkeiten: </div>
      )}
      <span className="dateItem">
        <label htmlFor="fromTime">{!option && <span style={{ color:"red" }}>*</span>}Uhrzeit von:</label>
        <br />
        <input type="time" onChange={handleFromTimeChange} id="fromTime" value={FromTime} />
      </span>
      <span className="dateItem">
        <label htmlFor="toTime">{!option && <span style={{ color:"red" }}>*</span>}Uhrzeit bis:</label>
        <br />
        <input type="time" onChange={handleToTimeChange} id="toTime" value={ToTime} />
      </span>
      {formtype === 'technikerschein' && (
        <>
        <br />
        <span className="dateItem">
          <label htmlFor="travelTime">zusätzliche Fahrzeit in Stunden:</label>
          <input type="text" onChange={handleTravelTimeChange} id="travelTime" value={TravelTime} placeholder="zB 1,5; 1:30"></input>
        </span>
        </>
      )}
    </div>
  );
};

export default ChooseDate;
