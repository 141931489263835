
import { useState, useCallback } from "react";
import './App.scss';
import Customer from "./Customer.js";
import Material from "./Material.js";
import Activities from "./Activities.js";
import Todoes from "./Todoes.js";
import ChooseDate from "./date.js";
import Signature from "./signature";
import submitToServer from "./submit.js";
import SaveButton from "./saveFormButton.js";
import { useNavigate } from 'react-router-dom';

const Technikerschein = ({ username, selectedEntry }) => {
  const [customer, setCustomer] = useState('');
  const [material, setMaterial] = useState('');
  const [activities, setActivities] = useState ('');
  const [todoes, setTodoes] = useState('');
  const [time, setTime] = useState('');
  const [signature, setSignature] = useState('');
  const navigate = useNavigate();

  const technician = username;

  const handleCustomerChange = useCallback((value) => {
    setCustomer(value);
  }, []);

  const handleMaterialChange = useCallback((value) => {
    setMaterial(value);
  }, []);

  const handleActivitiesChange = useCallback((value) => {
    setActivities(value);
  }, []);

  const handleTodoesChange = useCallback((value) => {
    setTodoes(value);
  }, []);

  const handleDateChange = useCallback((value) => {
    setTime(value);
  }, []);

  const handleSignatureChange = useCallback((value) => {
    setSignature(value);
  }, []);

  const handleSaveClick = () => {
    let data = ['technikerschein', technician, customer, '', material, activities, todoes, time, signature]
    return data;
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    let data = ['technikerschein', technician, customer, material, activities, todoes, time, signature]
    if (data[2] === "" || data[6][1] === "" || data[6][2] === "") {
      window.alert('Bitte alle mit * markierten Felder ausfüllen');
      return;
    } else {
      submitToServer(data);
      navigate(`/`)
    }
  }

  return(
    <form onSubmit={handleSubmit}>
      <h1>Technikerschein</h1>
      <label htmlFor="technician">Techniker</label><br />
      <input type="text" name="technician" id="technician" value={technician} readOnly />
      {!selectedEntry ? (
        <>
        <Customer onClientChange={handleCustomerChange} form={'technikerschein'} />
        <Material onMaterialChange={handleMaterialChange} />
        <Activities onActivitiesChange={handleActivitiesChange} />
        <Todoes onTodoesChange={handleTodoesChange} />
        <ChooseDate onDateChange={handleDateChange} formtype={'technikerschein'} />
        <Signature savedSignature={handleSignatureChange} />
        </>
      ) : (
        <>
        <Customer onClientChange={handleCustomerChange} restoredEntry={selectedEntry.customerName} form={'technikerschein'} />
        <Material onMaterialChange={handleMaterialChange} restoredEntry={selectedEntry.material}/>
        <Activities onActivitiesChange={handleActivitiesChange} restoredEntry={selectedEntry.activities} />
        <Todoes onTodoesChange={handleTodoesChange} restoredEntry={selectedEntry.todoes} />
        <ChooseDate onDateChange={handleDateChange} formtype={'technikerschein'}  restoredEntry={selectedEntry.date} />
        <Signature savedSignature={handleSignatureChange} restoredEntry={selectedEntry.signature} />
        </>
      )
      }
      <SaveButton saveClick={handleSaveClick} />
      <button type="submit" className="customButton" id="submit">Abschicken</button>
    </form>
  )
}

export default Technikerschein