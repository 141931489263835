//react component for header

import logo from './img/logo_retina_big.png'

function back() {
  window.history.back();
}

function logout() {
  if (window.confirm('Möchten Sie sich ausloggen?')) {
    document.cookie = `user=; expires==Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    document.cookie = `role=; expires==Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    localStorage.removeItem("token");
    window.location.assign('/')
  }
}

function Header({ loggedIn }) {
    return (
      <>
      <img id='logo' src={logo} />
      <header>
        {loggedIn ? <button type="button" className="headerButton" id="backButton" onClick={back}>Zurück</button> : <span className="placeholder"> </span>}
        <span> </span>
        {loggedIn ? <button type="button" className="headerButton" id="logoutButton" onClick={logout}>Logout</button> : <span className="placeholder"> </span>}
      </header>
      </>
    );
  }
  
  export default Header;