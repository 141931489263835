const SaveButton = ({ saveClick }) => {

    const handleClick = async() => {

        const data = saveClick();

        try{
            await fetch('http://checklist.epserver.at:4343/saveForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem("token")}`
                },
                body: JSON.stringify(data)
            })
            .then(response => response.text())
            .then(message => {
                if (message === "OK") {
                    window.alert("Speichern erfolgreich")
                } else {
                    window.alert('Speichern fehlgeschlagen')
                }
            })
            .catch(err => {
                console.log('Fehler beim Speichern: ', err)
                return;
            });
        } catch (error) {
            console.log('Fehler bei Verbindung zum Server: ', error)
            return;
        }
    }

    return(
        <button type="button" className="customButton" id="saveButton" onClick={handleClick}>Formular zwischenspeichern</button>
    )
}

export default SaveButton;