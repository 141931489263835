import React, { useRef, useEffect } from 'react';
let drawing = false;

const Signature = ({ savedSignature, restoredEntry }) => {
    const canvasRef = useRef(null);
    const contextRef = useRef(null);

    const img = new Image();
    if (restoredEntry) img.src = restoredEntry;

    const saveSignature = () => {
        const canvas = canvasRef.current;
        const signatureImage = canvas.toDataURL();
        savedSignature(signatureImage);
    }
    
    const getOffset = (clientX, clientY) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;
        return { offsetX, offsetY };
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.lineWidth = 2;
        context.strokeStyle = '#000';
        contextRef.current = context;
        context.drawImage(img, 0, 0)
        saveSignature();
        
        // IPAD seems to confuse order of events, so we have to add the event listeners manually
        canvas.addEventListener('touchstart', startDrawing, { passive: false });
        canvas.addEventListener('touchmove', draw, { passive: false });

        // Clean up function to prevent memory leaks
        return () => {
            canvas.removeEventListener('touchstart', startDrawing);
            canvas.removeEventListener('touchmove', draw);
        };
    }, []);

    const startDrawing = (e) => {
        e.preventDefault();
        drawing = true;
        if (e.type === 'mousedown') {
          const { offsetX, offsetY } = e.nativeEvent;
          contextRef.current.beginPath();
          contextRef.current.moveTo(offsetX, offsetY);
        } else if (e.type === 'touchstart') {
          const { clientX, clientY } = e.touches[0];
          const { offsetX, offsetY } = getOffset(clientX, clientY);
          contextRef.current.beginPath();
          contextRef.current.moveTo(offsetX, offsetY);
        }
    };

    const draw = (e) => {
        if (!drawing) return;
        e.preventDefault();
        if (e.type === 'mousemove') {
          const { offsetX, offsetY } = e.nativeEvent;
          contextRef.current.lineTo(offsetX, offsetY);
        } else if (e.type === 'touchmove') {
          const { clientX, clientY } = e.touches[0];
          const { offsetX, offsetY } = getOffset(clientX, clientY);
          contextRef.current.lineTo(offsetX, offsetY);
        }
    
        contextRef.current.stroke();
    };

    const stopDrawing = (e) => {
        e.preventDefault();
        drawing = false;
        if (e.type === 'mouseup' || e.type === 'touchend') {
          contextRef.current.closePath();
          saveSignature();
        }
    }

    const clearSignature = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        savedSignature('');
    }

    let canvasWidth
    window.screen.width > 800 ? canvasWidth = 800 : canvasWidth = window.screen.width * 0.8;

    return(
        <div id="signature">
            <label htmlFor='signatureCanvas'>Unterschrift</label>
            <br />
            <canvas
                id='signatureCanvas'
                ref={canvasRef}
                width={canvasWidth}
                height={200}
                style={{ border: '1px solid #000', touchAction: 'none'}}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onTouchEnd={stopDrawing}
            />
            <br />
            <button type="button" id='clearSignature' onClick={clearSignature}>Unterschrift löschen</button> 
        </div>
    )
}

export default Signature;