//React component for the checkboxes

import { useState, useEffect, useCallback } from "react";
const Boxes = require('./checkboxes.json');

const CheckboxGroup = ({ options, onChange, checkedboxes }) => {
  const listItems = options.map((box, index) => {
    const handleCheckboxChange = (e) => {
      onChange(e.target.value, e.target.checked);
    };
    const checked = checkedboxes.includes(box);

    return (
      <span key={index}>
        <input
          type="checkbox"
          id={box}
          value={box}
          checked={checked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor={box}>{box}</label>
      </span>
    );
  });
  return listItems;
};

//start of component
const Checkboxes = ({ onCheckboxChange, form, restoredEntry }) => {
    const [checkedboxes, setCheckedboxes] = useState([]); //use array to safe the ticked boxes

    const handleCheckboxChange = useCallback((value) => {
      if (checkedboxes.includes(value)) {
        setCheckedboxes(checkedboxes.filter((box) => box !== value));
      } else {
        setCheckedboxes([...checkedboxes, value]);
      }
    });

  let restoredArr
  if (restoredEntry) {
    restoredArr = restoredEntry.split('&&');
    restoredArr.splice(-1);
  } else {
    restoredArr = []
  };

  useEffect(() => {
    setCheckedboxes(restoredArr);
  }, [restoredEntry]);

  useEffect(() => {
    onCheckboxChange(checkedboxes)
  }, [checkedboxes]);

  return (
    <div id="checkboxes">
      {form === "Farbkalibrierung" ? (
        <div id="printboxes" className="checks">
          <CheckboxGroup options={Boxes.Drucker} onChange={handleCheckboxChange} checkedboxes={checkedboxes} />
        </div>
      ) : (
        <>
        <label>Drucker:</label>
        <div id="printboxes" className="checks">
          <CheckboxGroup options={Boxes.Drucker} onChange={handleCheckboxChange} checkedboxes={checkedboxes} />
        </div>
        <label>NAS:</label>
        <div id="nasboxes" className="checks">
          <CheckboxGroup options={Boxes.NAS} onChange={handleCheckboxChange} checkedboxes={checkedboxes} />
        </div>
        <label>PC:</label>
        <div id="pcboxes" className="checks">
          <CheckboxGroup options={Boxes.PC} onChange={handleCheckboxChange} checkedboxes={checkedboxes} />
        </div>
        {form === "local" &&
          <>
          <label>Schneidmaschine:</label>
          <div id="cutboxes" className="checks">
            <CheckboxGroup options={Boxes.Schneidmaschine} onChange={handleCheckboxChange} checkedboxes={checkedboxes} />
          </div>
          </>}
        </>
      )}
    </div>
  );
};

export default Checkboxes;