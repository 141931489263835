const submitToServer = async (data) => { //send data to server
    try {
      const response = await fetch('http://checklist.epserver.at:4343/formular', { //POST with parameter /formular
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      });

      if (response.ok) {
          console.log('Daten erfolgreich gesendet');
          window.alert('Formular abgeschickt');
      } else {
          console.error('Fehler beim Senden');
          window.alert('Fehler beim Senden');
      }
  } catch (error) {
      console.error('Fehler: ', error);
  }
}

export default submitToServer;