import { useEffect, useState } from "react";

const Archive = ({ username }) => {
  const [allEntries, setAllEntries] = useState([]);

  const fetchTableData = () => {
    try {
      fetch('http://checklist.epserver.at:4343/loadForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username: username, table: 'archive' })
      })
        .then(response => response.json())
        .then(allEntries => {
          setAllEntries(allEntries);
        })
        .catch(err => {
          console.log('Fehler bei der Abfrage: ', err);
        });
    } catch (error) {
      console.log('Fehler bei der Verbindung zum Server: ', error);
    }
  }

  useEffect(() => {
    fetchTableData();
  }, [username]);

  return (
    <div id="archive">
      <h1>Archiv</h1>
      <div id="archiveList">
        <div id="savedFormsRow1">
            <span>Name des Kunden</span>
            <span>Formular</span>
            <span>gesendet am</span>
            <span>Link</span>
            <span></span>
        </div>
        {
          allEntries.toReversed().map(item => {
          const fullDate = new Date(item.saveDate);
          const linkToPdf = item.link.replaceAll(" ", "_");
          const date = `${String(fullDate.getDate()).padStart(2, 0)}. ${String(fullDate.getMonth()+1).padStart(2, 0)}. ${fullDate.getFullYear()}, ${String(fullDate.getHours()).padStart(2, '0')}:${String(fullDate.getMinutes()).padStart(2, '0')} Uhr`

          return (
            <div key={item.ID}>
              <span>{item.customer.replaceAll("_", " ")}</span>
              <span>{item.form.charAt(0).toUpperCase()+item.form.slice(1)}</span>
              <span>{date}</span>
              <a href={`http://checklist.epserver.at/${linkToPdf}`} target="_blank">Link zum PDF</a>
            </div>
          )

          }
        )}
      </div>
    </div>
  )
}

export default Archive