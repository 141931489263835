//React component for the main menu

import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getCookieName } from "./index.js";

function Menu({ userRole }) {
    const [menuEntry, setMenuEntry] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        setMenuEntry(
            ['Technikerschein',
            'Remote-Wartung',
            'Vor-Ort-Wartung',
            'Farbkalibrierung',
            'gespeicherte Formulare',
            'Archiv']
            )
        if (userRole === 'Administrator'){
            setMenuEntry(prevMenuEntry => [...prevMenuEntry, 'Admin-Bereich'])
        }
    }, [])

    const handleClick = (e) => {
        let cookie = getCookieName('user');
        if (cookie === null) {
            window.alert('Session ist abgelaufen. Bitte erneut anmelden.')
            window.location.reload();
        }
        navigate(`/${e.target.textContent.toLowerCase()}`)
    }

    return (
        <div id="mainMenu">
            <h1>Willkommen</h1>
            <div id="menuEntries">
                {menuEntry.map((entry, index) => {
                    if (entry === 'Admin-Bereich') {
                        return (
                            <div key={index}>
                                <hr />
                                <h2 onClick={handleClick} className="menuButton">{entry}</h2>
                            </div>
                        )
                    } else {
                        return (
                            <h2 key={index} onClick={handleClick} className="menuButton">{entry}</h2>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default Menu;